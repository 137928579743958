$(document).ready(function () {
    "use strict";
    /*
     ----------------------------------------------------------------------
     loading animation
     ----------------------------------------------------------------------
     */
    $('.loader').delay(400).fadeOut();
    $('.loading').delay(400).fadeOut("fast");
    $('#after-loading').css('visibility', 'visible');
    /*
     ----------------------------------------------------------------------
     Scroll
     ----------------------------------------------------------------------
     */
    //Check to see if the window is top if not then display button
    $(window).scroll(function () {
        if ($(this).scrollTop() > 400) {
            $('.scrollToTop').fadeIn();
        } else {
            $('.scrollToTop').fadeOut();
        }
    });
    //Click event to scroll to top
    $('.scrollToTop').on('click', function () {
        $('html, body').animate({scrollTop: 0}, 800);
        return false;
    });
    /*
     ----------------------------------------------------------------------
     Animation
     ----------------------------------------------------------------------
     */
    $('.animated').appear(function () {
        const elem = $(this);
        const animation = elem.data('animation');
        if (!elem.hasClass('visible')) {
            const animationDelay = elem.data('animation-delay');
            if (animationDelay) {
                setTimeout(function () {
                    elem.addClass(animation + " visible");
                }, animationDelay);
            } else {
                elem.addClass(animation + " visible");
            }
        }
    });
});
