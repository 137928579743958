import 'bootstrap'; // Importing JS file for Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'; // Importing CSS file for Bootstrap
import './css/ie10-viewport-bug-workaround.css' // Importing  ie10-viewport-bug-workaround CSS file
import './font-awesome-pro-5.15.4/css/all.min.css' // Importing Font-Awesome CSS file (No need for JS file: NO SVG).
import 'ie10-viewport-bug-workaround.js' // Importing  ie10-viewport-bug-workaround JS file
import 'animate.css' // Importing animate.css package that is needed for animation in the main page.
import 'jquery.appear' // Importing jquery.appear package that is needed for animation in the main page.
import './css/style.css' // The main CSS file for site.
import './css/color-blue.css' // The blue template for site.
import './css/responsive.css' // The CSS for responsive site.
import './css/google-fonts.css' // The fonts needed for site.
import './css/custom.css' // My custom CSS settings.
import './js/main.js' // The main JS for site.
console.log('Hello farhour.com'); // Test page
